<template>
    <div class="changePrice">
        <!-- 改数 -->
        <a-modal :footer="null" :title="'改数：' + changeGoods.goods_name" :visible="showModal" @cancel="handleCancel" width="50vw">
            <div class="changePrice_box">
                <div class="title">商品基本信息</div>
                <div class="row">
                    <div class="left">零售价：</div>
                    <div class="right"><b>￥{{changeGoods.goods_price}}</b></div>
                </div>
                <div class="row">
                    <div class="left">当前数量：</div>
                    <div class="right">{{changeGoods.refund_num}}{{changeGoods.is_weighing == 2 ? '  KG' : ''}}</div>
                </div>
                <div class="row">
                    <div class="left">修改数量：</div>
                    <div class="right">
                        <a-input class="inp" v-model="thisNum" v-if="changeGoods.is_weighing == 2" suffix="| KG" @pressEnter="okHandle"/>
                        <a-input class="inp" v-model="thisNum" v-else @pressEnter="okHandle"/>
                    </div>
                </div>
                <div :style="'height:' + height + 'vh;margin-top:5vh;'">
                    <numCode :height="height" v-model="thisNum" @okHandle="okHandle"></numCode>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
export default {
    name:'changePrice',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        activeGoods:{
            default:0,
            type:Number
        }
    },
    components:{ numCode },
    data(){
        return{
            height:32,
            thisNum:'',
            changeGoods:{}
        }
    },
    watch:{
        showModal(val){
            if(val){
                this.changeGoods = this.returnGoods[this.activeGoods]
                console.log(this.changeGoods);
            }else{
                this.thisNum = ''
                this.changeGoods = {}
            }
        },
        thisNum(val){
            if(this.changeGoods.is_weighing == 2){
                this.$set(this , 'thisNum' , this.getTofixed(val))
            }else{
                this.$set(this , 'thisNum' , this.getInteger(val))
            }
        }
    },
    computed: {
        ...mapState({
            returnGoods:state=>state.fast_food.returnGoods,
        }),
    },
    methods:{
        ...mapMutations(['set_returnGoods']),
        handleCancel(){
            this.$emit('closeHandle')
        },
        okHandle(){
            if(this.thisNum){
                if(this.changeGoods && this.changeGoods.total_num && this.thisNum > this.changeGoods.total_num){
                    this.$message.warning('退货数量不能大于订单数量')
                    return false
                }
                let list = JSON.parse(JSON.stringify(this.returnGoods))
                list[this.activeGoods].refund_num = this.thisNum
                this.set_returnGoods(list)
                this.handleCancel()
            }else{
                this.$message.error('请输入数量')
            }
        },
    }
}
</script>
<style lang="less" scoped>
.changePrice_box{
    .title{
        font-weight: 700;
        font-size: 2.5vh;
        margin-bottom: 2vh;
    }
    .row{
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        font-size: 2.2vh;
        .left{
            flex: 1;
        }
        .right{
            flex: 4;
        }
    }

    .ant-input{
        height: 5.2vh;
        font-size: 2.5vh!important;
    }
    ::v-deep .ant-input-affix-wrapper{
        height: 4.2vh;
        .ant-input-suffix{
            font-size: 2.5vh;
            margin: 0.5vh 0;
        }
        .ant-input{
            height: 5.2vh;
            font-size: 2.5vh!important;
        }
    }
}
</style>