<template>
    <div class="returnGoods">
        <!-- 退货 -->
        <div class="left">
            <div class="shangyidan">上一单：{{preOrderData.refund_order_no}}</div>
            <div class="money">
                <div class="money_left">
                    <div>
                        应退：
                        <b>￥{{preOrderData.refund_price}}</b>
                    </div>
                    <div>
                        实退：
                        <b>￥{{preOrderData.refund_price}}</b>
                    </div>
                </div>
                <!-- <div class="money_right">
                    <div>
                        找零：
                    </div>
                    <b>￥0.00</b>
                </div> -->
            </div>
            <div class="inputBox">
                <!-- <a-input placeholder="商品名称/编号/条码" /> -->
                <searchInput 
                @search="handleSearch"
                @change="handleChange" 
                @clearData="searchData = [];orderList=[]" 
                placeholder="商品名称/编号/条码/订单号"
                :searchData="searchData"
                :orderList="orderList"
                :clearVal="clearVal"
                :haveBlur="haveBlur"
                ></searchInput>
            </div>
            <div class="member" v-if="returnMember && returnMember.user_id">
                <img :src="returnMember.avatar_url" v-if="returnMember.avatar_url != null" alt="">
                <img src="../../assets/default-avatar.png" v-else alt="">
                <div class="info">
                    <div class="photo">{{returnMember.mobile}}</div>
                    <div>余额:￥{{returnMember.balance}}</div>
                </div>
                <div class="close">
                    <a-icon type="close" @click="deleteMember"/>
                </div>
            </div>
            <div class="returnGoodsList" :style="returnMember && returnMember.user_id ? 'height:51vh;' : 'height:58.4vh'">
                <div v-if="returnGoods && returnGoods.length">
                    <div :class="activeGoods == i ? 'goods_item hoverbgc checkedbgc' : 'goods_item hoverbgc'" @click="checkCartGoods(i)" v-for="(item,i) in returnGoods" :key="i">
                        <div class="name">{{item.goods_name}}<span v-if="item.guige">（{{item.guige}}）</span></div>
                        <div class="zuofa">
                            <!-- <span>{{item.zuofa}}</span>
                            <span>{{item.zuofa && item.kouwei ? '、' : ''}}</span>
                            <span>{{item.kouwei}}</span>
                            <span>{{item.kouwei && item.jikou ? '、' : ''}}</span>
                            <span>{{item.jikou}}</span> -->
                        </div>
                        <div class="price_num">
                            <div class="price">{{(item.goods_price * item.refund_num).toFixed(2)}}</div>
                            <div class="num" v-if="returnData && returnData.type == 'order' && returnData.orderReturn == 1">
                                <span class="iconfont icon-jianqu jinyong"></span>
                                <span class="inp">{{item.refund_num}}</span>
                                <span class="iconfont icon-jia jinyong"></span>
                            </div>
                            <div class="num" v-else>
                                <span class="iconfont icon-jianqu" @click="changeNum(item,'jian',i)"></span>
                                <span class="inp">{{item.refund_num}}</span>
                                <span class="iconfont icon-jia" @click="changeNum(item,'jia',i)"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="totalNum">退货数量：{{total_num.toFixed(2)}}</div>
            <div class="jiezhangBtn" @click="settlementConfirm">
                <a-icon type="plus" />
                退款结算￥{{total_money}}
            </div>
        </div>
        <div class="center">
            <div :class="returnMember && returnMember.user_id ? 'bgColor' : ''" @click="selectMember">会员<b>(F1)</b></div>
            <div @click="changeNumHandle('num')">数量<b>(F2)</b></div>
            <div @click="changeNumHandle('price')">改价<b>(F3)</b></div>
            <div @click="deleteGoods">删除<b>(F4)</b></div>
            <div @click="empty">清空</div>
            <!-- <div>钱箱</div> -->
        </div>
        <div v-if="returnData && returnData.type === 'order'" class="rightOrderReturn">
            <div class="title">
                <div>
                    订单退货
                    <span>-订单号{{returnData.order_no}}</span>
                </div>
                <div class="clearOrder" @click="clearOrder">清除订单</div>
            </div>
            <div class="info">
                <div>实收金额：<b>{{returnData.payed_price}}</b></div>
                <div>订单状态：
                    <b v-if="returnData.pay_status == 10">未结算</b>
                    <b v-else-if="returnData.pay_status == 20">已结算</b>
                    <b v-else-if="returnData.pay_status == 30">已结算挂账</b>
                    <b v-else-if="returnData.pay_status == 60">已退款</b>
                </div>
                <div>订单时间：<b>{{returnData.create_time}}</b></div>
            </div>
            <!-- <a-radio-group v-model="returnData.orderReturn" @change="returnDataChange">
                <a-radio value="1">整单退款</a-radio>
                <a-radio value="2">部分退款</a-radio>
            </a-radio-group> -->
            <!-- 整单退款 -->
            <a-table :columns="columns" :data-source="tableData" bordered style="margin-top:2vh;"
            v-if="returnData.orderReturn == 1"
            rowKey="order_goods_id" :pagination="false" :locale="{emptyText: '暂无数据'}">
                <template slot="goods_name" slot-scope="record">
                    <div>{{record.goods_name}}</div>
                </template>
                <template slot="refund_num" slot-scope="record">
                <!-- <a-input-number v-model="record.refund_num" disabled/> -->
                {{record.refund_num}}
                </template>
                <template slot="refund_stock" slot-scope="record">
                <a-switch v-model="record.refund_stock" @change="switchChange($event,record)" checked-children="是" un-checked-children="否"/>
                </template>
                <template slot="refund_price" slot-scope="record">
                {{record.refund_price}}
                </template>
            </a-table>
            <!-- 部分退款 -->
            <a-table :columns="columns" :data-source="tableData" bordered v-else style="margin-top:2vh;" rowKey="order_goods_id" :pagination="false"
             :rowSelection="{ selectedRowKeys: selectedRowKeys, onSelect: onSelectChange , onSelectAll : onSelectAll}" :locale="{emptyText: '暂无数据'}">
            <template slot="refund_num" slot-scope="record">
              <!-- <a-input-number v-model="record.refund_num"/> -->
              {{record.refund_num}}
            </template>
            <template slot="refund_stock" slot-scope="record">
              <a-switch v-model="record.refund_stock" checked-children="是" un-checked-children="否"/>
            </template>
            <template slot="total_pay" slot-scope="record">
              {{(record.grade_goods_price * 1 * record.total_num).toFixed(2)}}
            </template>
          </a-table>
        </div>
        <div class="right" v-else>
            <div class="classify">
                <div :class="'all ' + (category_id == 'all' ? 'bgColor' : '')" @click="goodsRequest('all')">全部</div>
                <div class="scroll_box">
                    <div class="scroll_box2" ref="scroll_box2" :style="'transform:translateX(-' + srrollWidth + 'vw)'">
                        <div v-for="(item,index) in cateList" :key="index" :class="category_id == item.category_id ? 'bgColor all' : 'all'" @click="goodsRequest(item.category_id)">{{item.name}}</div>
                    </div>
                </div>
                <div class="xiang" style="width:4vw;" @click="showAllCate = true">
                    <span class="iconfont icon-xiangyou" style=""></span>
                </div>
                <div class="allBox" v-if="showAllCate">
                    <div :class="'all ' + (category_id == 'all' ? 'bgColor' : '')" @click="goodsRequest('all')">全部</div>
                    <div v-for="(item,index) in cateList" :key="index" :class="category_id == item.category_id ? 'bgColor all' : 'all'" @click="goodsRequest(item.category_id)">{{item.name}}</div>
                    <div class="all" style="width:4vw;" @click="showAllCate = false">
                        <span class="iconfont icon-xiangshang" style=""></span>
                    </div>
                </div>
            </div>
            <div class="goods_list">
                <div :style="'width:' + (58 - rowNum ) / rowNum + 'vw;height:' + (model_type==2 ? 62 : 102) / rowNum + 'vh;font-size:' + (model_type==2 ? 12 : 10) / rowNum + 'vh'"
                class="goods_item" v-for="(item,i) in goodsList" :key="i" @click="checkGoods(item)">
                    <div v-if="model_type !=2">
                        <div class="img_box">
                            <img :src="item.preview_url" v-if="item.preview_url" alt="">
                            <img src="../../assets/defaultImg.png" v-else alt="">
                        </div>
                        <div class="name">{{item.goods_name}}</div>
                        <div class="price">￥{{item.goods_price}}</div>
                    </div>
                    <div class="bigFont" v-if="model_type==2">
                        <div >
                            <div class="names">{{item.goods_name}}</div>
                            <div class="price">￥{{item.goods_price}}</div>
                        </div>
                    </div>
                    <div v-if="item.is_weighing == 2" class="weighing">称重</div>
                    <!-- <div v-if="item.is_soldout===2" class="weighing">已售罄</div> -->
                    <!-- <div v-if="item.limited_stock_total !=0.00" class="weighing">限量沽清</div> -->
                </div>
            </div>
        </div>
        <div class="searchGoods" v-if="!showSearchGoods">
            <div class="searchInp">
                <a-input class="inp" @pressEnter="searchGoodsHandle" placeholder="请输入商品名称" v-model="goods_name"/>
                <span @click="searchGoodsHandle">查询</span>
            </div>
            <div class="searchGoodsList" v-if="searchGoodsList && searchGoodsList.length">
                <div class="item hoverbgc" v-for="(item,i) in searchGoodsList" :key="i" @click="checkGoods(item)">
                    {{item.goods_name}}
                </div>
            </div>
            <div class="searchGoodsList noGoods"  v-if="searchGoodsList.length == 0 && showNoGoods">没有搜到该商品</div>
        </div>
        <changePrice :showModal="showChangePrice" @closeHandle="showChangePrice = false" :activeGoods="activeGoods"></changePrice>
        <changeNum :showModal="showChangeNum" @closeHandle="closeChangeNum" :activeGoods="activeGoods"></changeNum>
        <member :showDrawer="showMember" @closeHandle="showMember = false" @okHandle="memberOkHandle" :isSettle="isSettle" :fromHuiyuanPay="fromHuiyuanPay"></member>
        <recharge :showModal="showRecharge" @closeHandle="showRecharge = false"></recharge>
        <weighing :showModal="showWeight" @closeHandle="showWeight = false" @openGuige="showWeight = false;showGuige = true"></weighing>
        <practice :showModal="showGuige" @closeHandle="showGuige = false"></practice>
        <confirmPrice :showModal="showReturn" @closeHandle="showReturn = false" @okHandle="settlement" :total_money="total_money"></confirmPrice>
    </div>
</template>
<script>
import { mapState , mapMutations } from "vuex";
import {categoryList,goodsList} from '@/request/business.js'
import changeNum from './modules/changeNum.vue'                      //改数量
import changePrice from './modules/changePrice.vue'                  //改价
import member from './modules/member.vue'                            //会员查询 会员注册 会员详情
import recharge from './modules/recharge.vue'                        //会员充值
import weighing from './modules/weighing.vue'                        //称重
import practice from './modules/practice.vue'                        //规格
import confirmPrice from './modules/confirmModal.vue'                //确认退款信息
import searchInput from '@/components/searchInput/searchInput.vue'
import { goodsRefund , billList , getPreOrder } from "@/request/fast_food_settle";
import { refundOrder , refundGoodsList } from '@/request/bill'
import {getScreenSetting} from '@/request/business.js'
export default {
    name:'returnGoods',
    data(){
        return{
            searchData:[],
            orderList:[],
            searchInputValue:'',
            clearVal:0,
            cateList:[],
            category_id:'all',
            goodsList:[],
            activeGoods:0,
            showSearchGoods:true,
            searchGoodsList:[],
            goods_name:'',
            showNoGoods:false,
            srrollWidth: 0,
            rightBtn:0,
            leftBtn:0,
            showPend:false,
            showChangePrice:false,
            showChangeNum:false,
            showMember: false,
            showRecharge:false,
            showWeight:false,
            showGuige:false,
            isSettle:false,  // 是否是结算页跳更换会员
            fromHuiyuanPay:false, //是否是未绑定会员点击结算页余额结算跳到的会员页
            have_order:false,
            tableData:[],
            columns:[
                {
                    title: "商品名称",
                    dataIndex: "goods_name",
                    key: "goods_name",
                    align:'center'
                },
                {
                    title: "总数量",
                    dataIndex: "total_num",
                    key: "total_num",
                    width: 80,
                    align:'center'
                },
                {
                    title: "退货数量",
                    key: "refund_num",
                    scopedSlots: { customRender: 'refund_num' },
                    align:'center'
                },
                {
                    title: "是否退库存",
                    key: "refund_stock",
                    scopedSlots: { customRender: 'refund_stock' },
                    align:'center'
                },
                {
                    title: "实收金额",
                    // dataIndex: "total_pay_price",
                    key: "total_pay",
                    scopedSlots: { customRender: 'total_pay' },
                    align:'center'
                },
            ],
            selectedRowKeys:[],
            preOrderData:{}, //上一单
            new_total_money:'',
            showReturn:false,
            model_type:"",//1正常模式 2大字模式
            rowNum: 6 , 
            showAllCate:false   //显示所有分类

        }
    },
    computed: {
        ...mapState({ 
            returnGoods:state=>state.fast_food.returnGoods,
            returnMember:state=>state.fast_food.returnMember,
            returnData:state=>state.fast_food.returnData,
            
        }),
        total_money(){
            let money = 0
            if(this.returnGoods && this.returnGoods.length){
                this.returnGoods.forEach(item => {
                    money += item.goods_price * 1 * item.refund_num
                });
                money = money.toFixed(2)
            }
            money = money > this.returnData.payed_price * 1 ? this.returnData.payed_price : money
            return money
        },
        total_num(){
            let num = 0
            if(this.returnGoods && this.returnGoods.length){
                this.returnGoods.forEach(item => {
                    num += item.refund_num * 1
                });
                // num = num.toFixed(2)
            }
            return num
        },
        have_payLog(){
            let have = false
            // if(this.orderInfo && this.orderInfo.paylog && this.orderInfo.paylog.length > 0){
            //     have = true
            // }
            return have
        },
        haveBlur(){
            let have = this.showSearchGoods && !this.showChangePrice 
            && !this.showChangeNum && !this.showMember && !this.showWeight && !this.showReturn
            return have 
        }
    },
    watch:{
        have_order(val){
            console.log(val);
        },
        total_money(val){
            const screenObject= JSON.parse(localStorage.getItem('screen'))?JSON.parse(localStorage.getItem('screen')):{use:2,com:undefined,potter:undefined,}
            const com=screenObject.com;
            const potter=parseFloat(screenObject.potter);
            if(val && screenObject.use==1){
                 bound.vScreen(com,potter,val,false)
            }else{
                bound.vScreen(com,potter,"0.00",false)
            }
        }

    },
    components:{ 
        changeNum , 
        changePrice , 
        member , 
        recharge , 
        weighing ,
        searchInput ,
        practice ,
        confirmPrice
    },
    mounted(){
        this.getDetail()
        this.getPreData()
        this.tabRequest();
        this.goodsRequest('all');
        let _this = this
        document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            let keycodeVue = e1 && e1.keyCode
            // console.log(keycodeVue);
            if(keycodeVue == 112){       //F1 会员
                e.preventDefault();   
                _this.showMember = true
            }else if(keycodeVue == 113){   //F2 数量
                e.preventDefault();
                _this.changeNumHandle('num')
            }else if(keycodeVue == 114){   //F3 改价
                e.preventDefault();
                _this.changeNumHandle('price')
            }else if(keycodeVue == 115){    //F4 删除
                e.preventDefault();
                _this.deleteGoods()
            }
        }
        if(this.returnData && this.returnData.type == 'order' && this.returnData.orderReturn == 1){
            let data = JSON.parse(JSON.stringify(this.returnData.orderGoods))
            this.tableData = data
            this.set_returnGoods(data)
        }else if(this.returnData && this.returnData.type == 'order' && this.returnData.orderReturn == 2){
            this.getTableData()
        }
        const screenObject= JSON.parse(localStorage.getItem('screen'))?JSON.parse(localStorage.getItem('screen')):{use:2,com:undefined,potter:undefined,}
        const com=screenObject.com;
        const potter=parseFloat(screenObject.potter);
        if(this.total_money && screenObject.use==1){
            bound.vScreen(com,potter,val,false)
        }else{
            bound.vScreen(com,potter,"0.00",false)
        }
        
    },
    methods:{
        ...mapMutations(['set_returnGoods' , 'set_weightGoods' , 'set_returnMember','set_returnData']),
        getPreData(){
            getPreOrder({order_type:2}).then(res => {
                if(res.data.status === 200){
                    this.preOrderData = res.data.data
                }
            })
        },
        checkGoods(item){
            if(this.have_payLog){
                this.$message.warning('订单已存在支付记录，不能继续添加商品')
            }else{
                this.have_order = false
                if(item.is_weighing == 2){
                    item.goods_original_price = item.goods_price
                    item.refund_num = 1
                    this.set_weightGoods(item)
                    this.showWeight = true
                }else if(item.spec_type == 20){
                    let is_spec_no = this.searchInputValue && (item.spec_content.indexOf(this.searchInputValue) > -1)
                    if(is_spec_no){
                        JSON.parse(item.spec_content).forEach(ele => {
                            if(ele.goods_no == this.searchInputValue){
                                item.guige = ele.spec_name
                                item.goods_price = ele.spec_price
                                item.goods_original_price = item.goods_price
                                let list = JSON.parse(JSON.stringify(this.returnGoods))
                                item.refund_num = 1
                                list.unshift(item)
                                this.set_returnGoods(list)
                                this.activeGoods = 0
                                this.searchInputValue = ''
                            }
                        })
                    }else{
                        item.refund_num = 1
                        this.set_weightGoods(item)
                        this.showGuige = true
                    }
                }else{
                    item.goods_original_price = item.goods_price
                    let list = JSON.parse(JSON.stringify(this.returnGoods))
                    item.refund_num = 1
                    list.unshift(item)
                    this.set_returnGoods(list)
                    this.activeGoods = 0
                }
            }
        },
        checkCartGoods(i){
            this.activeGoods = i
        },
        changeNum(item,type,i){
            if(type === 'jia'){
                if(this.returnData.type == 'order' && (item.refund_num > item.total_num || item.refund_num == item.total_num)){
                    this.$message.warning('退货数量不能超出订单数量')
                    let list = JSON.parse(JSON.stringify(this.returnGoods))
                    list[i].refund_num = Number(list[i].total_num)
                    let str = String(list[i].refund_num)
                    if(str.split('.') && str.split('.')[1] && str.split('.')[1].length > 2){
                        list[i].refund_num = list[i].refund_num.toFixed(2)
                    }
                    this.set_returnGoods(list)
                    this.getTableData()
                }else{
                    let list = JSON.parse(JSON.stringify(this.returnGoods))
                    list[i].refund_num = Number(list[i].refund_num) + 1
                    let str = String(list[i].refund_num)
                    if(str.split('.') && str.split('.')[1] && str.split('.')[1].length > 2){
                        list[i].refund_num = list[i].refund_num.toFixed(2)
                    }
                    this.set_returnGoods(list)
                    if(this.returnData && this.returnData.type === 'order'){
                        this.getTableData()
                    }
                }
                // if(item.refund_num < item.stock_total || item.is_oversold == 2){
                    
                // }else{
                //     this.$message.warning('库存不足')
                // }
            }else{
                if(item.refund_num > 1){
                    let list = JSON.parse(JSON.stringify(this.returnGoods))
                    list[i].refund_num = Number(list[i].refund_num) - 1
                    let str = String(list[i].refund_num)
                    if(str.split('.') && str.split('.')[1] && str.split('.')[1].length > 2){
                        list[i].refund_num = list[i].refund_num.toFixed(2)
                    }
                    this.set_returnGoods(list)
                    if(this.returnData && this.returnData.type === 'order'){
                        this.getTableData()
                    }
                }else{
                    let _this = this
                    this.$confirm({
                        title: "提示",
                        content: "确定要删除该商品吗？",
                        okText: "确定",
                        cancelText: "取消",
                        onOk(){
                            let list = JSON.parse(JSON.stringify(_this.returnGoods))
                            list.splice(i,1)
                            _this.set_returnGoods(list)
                            if(_this.returnData && _this.returnData.type === 'order'){
                                _this.getTableData()
                            }
                            
                            if(_this.activeGoods > 0 && (_this.activeGoods + 1 > _this.returnGoods.length)){
                                _this.activeGoods -= 1
                            }
                        }
                    });
                }
                
            }
        },
        changeNumHandle(type){
            if(this.have_payLog){
                this.$message.warning('订单已存在支付记录，不能进行该操作')
            }else if(this.returnData && this.returnData.type == 'order' && this.returnData.orderReturn == 1){
                this.$message.warning('整单退款不能进行该操作')
            }else{
                this.have_order = false
                let list = JSON.parse(JSON.stringify(this.returnGoods))
                console.log('list',list);
                if(type === 'num'){
                    if(list && list.length){
                        this.showChangeNum = true
                    }else{
                        this.$message.warning('没有可改数商品')
                    }
                }else if(type === 'price'){
                    if(list && list.length){
                        this.showChangePrice = true
                    }else{
                        this.$message.warning('没有可改价商品')
                    }
                }
            }
        },
        closeChangeNum(){
            this.showChangeNum = false
            if(this.returnData && this.returnData.type === 'order'){
                this.getTableData()
            }
        },
        deleteGoods(){
            if(this.have_payLog){
                this.$message.warning('订单已存在支付记录，不能进行该操作')
            }else if(this.returnData && this.returnData.type == 'order' && this.returnData.orderReturn == 1){
                this.$message.warning('整单退款不能进行该操作')
            }else{
                this.have_order = false
                let list = JSON.parse(JSON.stringify(this.returnGoods))
                if(list && list.length){
                    list.splice(this.activeGoods,1)
                    this.set_returnGoods(list)
                    if(this.returnData && this.returnData.type === 'order'){
                        this.getTableData()
                    }
                    if(this.activeGoods > 0 && (this.activeGoods + 1 > list.length)){
                        this.activeGoods -= 1
                    }
                }else{
                    this.$message.warning('没有可删除商品')
                }
            }
        },
        empty(){
            if(this.returnData && this.returnData.type == 'order' && this.returnData.orderReturn == 1){
                this.$message.warning('整单退款不能进行该操作')
            }else{
                this.have_order = false
                if(this.returnGoods && this.returnGoods.length){
                    let _this = this
                    this.$confirm({
                        title: "提示",
                        content: "确定要清空吗？",
                        okText: "确定",
                        cancelText: "取消",
                        onOk() {
                            _this.set_returnGoods([])
                            if(_this.returnData && _this.returnData.type === 'order'){
                                _this.getTableData()
                            }
                        },
                    });
                }else{
                    this.$message.warning('没有可清空商品')
                }
            }
        },
        async handleSearch(value) {
            if(value){
                const message = {
                    page:1,
                    keywords:value,
                    page_size:1000,
                }   
                this.searchInputValue = value
                await goodsList(message).then((res) => {
                    if(res.data.status===200){
                        const list = res.data.data.list && res.data.data.list.data;
                        this.searchData = list;
                    }           
                })
                await billList({order_no:value}).then(res => {
                    if(res.data.status===200){
                        const list = res.data.data.list && res.data.data.list.data;
                        this.orderList = list;
                    } 
                })
                if(this.searchData.length + this.orderList.length == 1){
                    if(this.searchData.length){
                        this.handleChange(this.searchData[0])
                    }else{
                        this.handleChange(this.orderList[0])
                    }
                }
                console.log('this.searchData',this.searchData,this.orderList);
            }
        },
        handleChange(value) {
            console.log(value);
            if(this.returnData && this.returnData.type == 'order'){
                this.$message.warning('当前有订单未处理，不能进行此操作')
                return
            }
            let i = this.searchData.findIndex(item => item.goods_id === value.goods_id)
            let ii = this.orderList.findIndex(item => item.order_no === value.order_no)
            if(i > -1){
                this.checkGoods(this.searchData[i])
                this.searchData = []
                this.orderList = []
                this.clearVal += 1
            }else if(ii > -1){
                if(this.returnGoods && this.returnGoods.length){
                    this.$message.warning('当前退货列表有商品未处理，不能进行订单退货')
                }else{
                    refundGoodsList({order_id : value.order_id}).then(resq => {
                        if(resq.data.status === 200){
                            billList({order_no:value.order_no}).then(res => {
                                if(res.data.status===200){
                                    let data = res.data.data.list && res.data.data.list.data && res.data.data.list.data[0];
                                    console.log(data);
                                    let list = []
                                    let refund = resq.data.data
                                    refund.forEach(item => {
                                        item.refund_num = item.total_num
                                        item.refund_stock = true
                                        item.goods_price = item.grade_goods_price
                                        // item.refund_total_num = item.total_num
                                        list.push(item)
                                    })
                                    data.orderGoods = refund
                                    // this.set_returnGoods(list)
                                    this.set_returnData({
                                        type:'order',
                                        orderReturn:'2',
                                        ...data
                                    })
                                    this.tableData = JSON.parse(JSON.stringify(list))
                                    this.searchData = []
                                    this.orderList = []
                                    this.clearVal += 1
                                }
                            })
                        }
                    })
                    
                }
            }
        },
        deleteMember(){ //删除会员
            this.set_returnMember({})
        },
        settlementConfirm(){ // 结算确认
            if(this.returnData && this.returnData.type === 'order'){
                if(Number(this.returnData.payed_price) > 0){
                    this.showReturn = true
                }else{
                    this.$message.warning('当前订单可退款金额为0')
                }
                
            }else{
                if(this.returnGoods && this.returnGoods.length){
                    const _this = this
                    this.$confirm({
                        title: "提示",
                        content: "确定要退款吗？",
                        okText: "确定",
                        cancelText: "取消",
                        onOk() {
                            _this.settlement()
                        },
                    });
                }else{
                    this.$message.warning('没有可结算商品')
                }
            }
        },
        settlement(val){ //结算
            // if(this.returnGoods && this.returnGoods.length){
                if(this.returnData && this.returnData.type === 'order'){
                    console.log('this.returnGoods',this.returnGoods);
                    console.log('this.tableData',this.tableData);
                    let data = {
                        order_id:this.returnData.order_id,
                        refund_type: '2',
                        refund_total_price: val ? val : this.total_money
                    }
                    let arr = []
                    this.returnGoods.forEach(item => {
                        arr.push({
                            goods_id:item.goods_id,
                            order_goods_id : item.order_goods_id,
                            goods_name : item.goods_name,
                            refund_total_num : item.refund_num,
                            refund_price : (item.goods_price * item.refund_num).toFixed(2), //(item.goods_price * item.refund_num).toFixed(2)
                            is_stock_total: item.refund_stock ? 1 : 2
                        })
                    })
                    data.refund_content = arr
                    refundOrder(data).then(res => {
                        console.log(res);
                        if(res.data.status == 200){
                            this.$message.success('退款成功')
                            this.set_returnGoods([])
                            this.set_returnData({})
                            this.getPreData()
                        }
                    })
                }else{
                    let refund = JSON.parse(JSON.stringify(this.returnGoods))
                    // refund.forEach(item => {
                    //     item.spec_content = item.spec_content || ''
                    // })
                    goodsRefund({
                        user_id:this.returnMember.user_id || '',
                        refund
                    }).then(res => {
                        if(res.data.status === 200){
                            this.$message.success('退款成功')
                            this.set_returnGoods([])
                            this.set_returnMember({})
                            this.getPreData()
                        }
                    })
                }
            // }else{
            //     this.$message.warning('没有可结算商品')
            // }
        },
        tabRequest(){  //商品分类请求
            categoryList().then((res) => {
                if(res.data.status===200){
                    const list =res.data.data.list && res.data.data.list;
                    this.cateList = list;
                    if(this.cateList.length > 5){
                        this.rightBtn = 4
                    }
                }           
            })
        },
        goodsRequest(id){    //商品列表请求
            this.category_id = id
            this.showAllCate = false
            let i = this.cateList.findIndex(item => item.category_id == id)
            let index = i > -1 ? i : 0
            if(index - 2 > -1 && index + 2 < this.cateList.length){
                this.srrollWidth = (index - 2) * 9.5
            }else if(index + 2 >= this.cateList.length ){
                this.srrollWidth = (this.cateList.length - 4) * 9.5
            }else{
                this.srrollWidth = 0
            }
            const message = {
                page:1,
                category_id: id == 'all' ? '' : id,
                page_size:1000,
            }   
            goodsList(message).then((res) => {
                if(res.data.status===200){
                    const list = res.data.data.list && res.data.data.list.data;
                    list.forEach(item => {
                        if(item.spec_type == 20){
                            let price = 0
                            JSON.parse(item.spec_content).forEach((ele,i) => {
                                if(i == 0){
                                    price = ele.spec_price
                                }else{
                                    price = (price > ele.spec_price ? ele.spec_price : price)
                                }
                            })
                            item.goods_price = Number(price).toFixed(2)
                        }
                    })
                    this.goodsList=list;
                }           
            })
        },
        searchGoodsHandle(){ //右侧搜索商品
            const message = {
                page:1,
                goods_name: this.goods_name,
                page_size:1000,
            }   
            goodsList(message).then((res) => {
                if(res.data.status===200){
                    const list = res.data.data.list && res.data.data.list.data;
                    this.searchGoodsList = list;
                    this.showNoGoods = true
                }           
            })
        },
        scrollHandle(type){ // 分类滚动
            let box_width = this.cateList.length * 8
            if(type === 'left'){
                this.rightBtn = 4
                if(this.srrollWidth < 32 || this.srrollWidth == 32){
                    this.srrollWidth = 0 
                    this.leftBtn = 0
                }else{
                    this.srrollWidth = this.srrollWidth - 32
                }
            }else{
                this.leftBtn = 4
                if(this.srrollWidth + 64 > box_width || this.srrollWidth + 64 == box_width){
                    this.srrollWidth = box_width - 40
                    this.rightBtn = 0
                }else{
                    this.srrollWidth = this.srrollWidth + 32
                    this.srrollWidth >= box_width - 40 ? this.rightBtn = 0 : ''
                }
            }
        },
        memberOkHandle(type){
            if(type === 'openRecharge'){
                this.showRecharge = true
            }else if(type === 'openSettle'){
                this.isSettle = false
                this.showMember = false
            }else if(type === 'openHuiyuanPay'){
                this.fromHuiyuanPay = false
                this.showMember = false
            }
        },
        returnDataChange(e){
            let val = e.target.value
            console.log(val);
            let data = JSON.parse(JSON.stringify(this.returnData))
            data.orderReturn = val
            this.set_returnData(data)
            this.tableData = JSON.parse(JSON.stringify(data.orderGoods)) 
            if(val == 1){
                this.set_returnGoods(data.orderGoods)
            }else{
                this.tableData = JSON.parse(JSON.stringify(data.orderGoods)) 
                this.tableData.forEach(item => {
                    item.refund_num = 0
                })
                this.set_returnGoods([])
            }
        },
        //表格复选框 取消/选中 某一行
        onSelectChange (row,check){
            console.log('----',row,check);
            if(check){
                let leftData = JSON.parse(JSON.stringify(this.returnGoods))
                let rightdata = JSON.parse(JSON.stringify(this.tableData))
                let i = leftData.findIndex(ele => ele.order_goods_id == row.order_goods_id)
                let ii = rightdata.findIndex(ele => ele.order_goods_id == row.order_goods_id)
                if(i > -1){
                    leftData[i].refund_num = leftData[i].total_num
                }else{
                    rightdata[ii].refund_num = rightdata[ii].total_num
                    leftData.push(rightdata[ii])
                }
                this.set_returnGoods(leftData)
                rightdata.splice(ii,1)
                this.tableData = rightdata
            }else{
                let leftData = JSON.parse(JSON.stringify(this.returnGoods))
                let rightdata = JSON.parse(JSON.stringify(this.tableData))
                let i = leftData.findIndex(ele => ele.order_goods_id == row.order_goods_id)
                let ii = rightdata.findIndex(ele => ele.order_goods_id == row.order_goods_id)
                leftData.splice(i,1)
                rightdata[ii].refund_num = 0
                this.set_returnGoods(leftData)
                this.tableData = rightdata
                let iii = this.selectedRowKeys.findIndex(item => item == row.order_goods_id)
                this.selectedRowKeys.splice(iii,1)
            }
        },
        //表格复选框 全选/反选
        onSelectAll(selected, selectedRows, changeRows){
            console.log(selected, selectedRows, changeRows);
            if(selected){
                let data = JSON.parse(JSON.stringify(this.returnData.orderGoods))
                let leftData = JSON.parse(JSON.stringify(this.returnGoods))
                let rightdata = JSON.parse(JSON.stringify(this.tableData))
                let arr = []
                data.forEach(item => {
                    let i = leftData.findIndex(ele => ele.order_goods_id == item.order_goods_id)
                    if(i > -1){
                        item.refund_stock = leftData[i].refund_stock
                        arr.push(item)
                    }else{
                        let ii = rightdata.findIndex(ele => ele.order_goods_id == item.order_goods_id)
                        item.refund_stock = rightdata[ii].refund_stock
                        arr.push(item)
                    }
                })
                this.set_returnGoods(arr)
                this.tableData = []
                this.selectedRowKeys = []
            }else{
                let leftData = JSON.parse(JSON.stringify(this.returnGoods))
                let rightdata = JSON.parse(JSON.stringify(this.tableData))
                rightdata.forEach(item => {
                    item.refund_num = 0
                })
                this.tableData = rightdata
                let newData = leftData.filter(item => {
                    return rightdata.findIndex(ele => ele.order_goods_id == item.order_goods_id) < 0
                })
                this.set_returnGoods(newData)
                this.selectedRowKeys = []
            }
        },
        getTableData(){
            let data = JSON.parse(JSON.stringify(this.returnData.orderGoods))
            let leftData = JSON.parse(JSON.stringify(this.returnGoods))
            let rightdata = JSON.parse(JSON.stringify(this.tableData))
            let arr = []
            this.selectedRowKeys = []
            data.forEach(item => {
                let i = leftData.findIndex(ele => ele.order_goods_id == item.order_goods_id)
                if(i > -1){
                    if(leftData[i].refund_num < leftData[i].total_num){
                        console.log(leftData[i].refund_num);
                        console.log(leftData[i].total_num);
                        item.refund_stock = leftData[i].refund_stock
                        item.refund_num = leftData[i].refund_num
                        arr.push(item)
                        if(item.refund_num > 0){
                            this.selectedRowKeys.push(item.order_goods_id)
                        }
                    }
                }else{
                    let ii = rightdata.findIndex(ele => ele.order_goods_id == item.order_goods_id)
                    if(ii > -1){
                        item.refund_stock = rightdata[ii].refund_stock 
                    }else{
                        item.refund_stock = true
                    }
                    item.refund_num = 0
                    arr.push(item)
                    if(item.refund_num > 0){
                        this.selectedRowKeys.push(item.order_goods_id)
                    }
                }
            })
            this.tableData = arr
        },
        switchChange(val,row){
            let data = JSON.parse(JSON.stringify(this.returnGoods))
            let i = data.findIndex(item => item.order_goods_id == row.order_goods_id)
            if(i > -1){
                data[i].refund_stock = val
            }
            this.set_returnGoods(data)
        },
        selectMember(){
            if(this.returnData && this.returnData.type === 'order'){
                this.$message.warning('订单退款不能绑定会员')
            }else{
                this.showMember = true
            }
        },
        clearOrder(){
            let _this = this
            this.$confirm({
                title: "提示",
                content: "确定要清除该订单吗？",
                okText: "确定",
                cancelText: "取消",
                onOk() {
                    _this.set_returnGoods([])
                    _this.set_returnData({})
                },
            });
        },
        getDetail(){
            getScreenSetting({key:'goods_model'}).then(res => {
            if(res.data.status === 200){
                const values=res.data.data.values.model_type;
                this.model_type=values|| 1
                this.rowNum=res.data.data.values.column_num || 6;
                console.log(this.rowNum)
            }
            })
        }
        
    },
    beforeDestroy() {
        document.onkeydown = ''
    },

}
</script>
<style lang="less" scoped>
.search_item{
    height:5vh;
    line-height:5vh;
    font-size:2vh;
}
.returnGoods{
    padding-top: 1vh;
    display: flex;
    width: 93.4vw;
    height: 100%;
    font-size: 1.8vh;
    .left{
        width: 25.5vw;
        border-right: 1px solid #e5e5e5;
        position: relative;
        .shangyidan{
            padding: 1vh 1vw 0;
        }
        .money{
            padding: 0 1vw;
            display: flex;
            // line-height: 3.6vh;
            margin: 0.8vh 0;
            .money_left{
                flex: 3;
                // border-right: 1px solid #e5e5e5;
                margin-right: 1vw;
                b{
                    font-size: 2vh;
                }
                >div:first-child{
                    margin-bottom: 0.6vh;
                }
            }
            .money_right{
                flex: 2;
                b{
                    font-size: 2.2vh;
                }
            }
        }
        .inputBox{
            padding: 1vh 1vw;
            ::v-deep .ant-select-selection{
                height: 5vh;
                font-size: 2.2vh;
                width: 100%;
            }
            ::v-deep .ant-select-selection--single{
                height: 5vh;
                font-size: 2.2vh;
                width: 100%;
            }
            ::v-deep .ant-select-selection__rendered{
                line-height: 4.5vh;
            }
            ::v-deep .ant-select-selection__placeholder{
                height: 5vh;
            }
            
        }
        .member{
            margin: 0 1vw;
            display: flex;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            padding: 1vh 1vh 1vh 1vw;
            border-radius: 0.5vh;
            img{
                width: 5vh;
                height: 5vh;
                border-radius: 5vh;
                margin-right: 1vw;
            }
            .info{
                flex: 1;
                color: #a6a6a6;
                font-size: 1.6vh;
                .photo{
                    font-size: 2vh;
                    font-weight: 700;
                    color: #505050;
                }
            }
            .close{
                margin-top: -1vh;
                font-size: 2vh;
                i{
                    cursor: pointer;
                }
                
            }
        }
        .returnGoodsList{
            margin-top: 1vh;
            // height: 63.5vh;
            overflow: auto;
            
            &::-webkit-scrollbar {
                width : 0px; 
                height: 0px;
            }
            .goods_item{
                padding: 1vh 1vw 0;
                .name,.zuofa{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .zuofa{
                    color: #a6a6a6;
                    font-size: 1.5vh;
                    height: 1.8vh;
                    line-height: 1.8vh;
                }
                .price_num{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #e5e5e5;
                    .price{
                        color: #ac33c1;
                    }
                    .num{
                        margin-right: 1vw;
                        display: flex;
                        align-items: center;
                        font-size: 2vh;
                        .icon-jianqu{
                            // color: #ac33c1;
                            color: #e5e5e5;
                            font-size: 2.6vh;
                            cursor: pointer;
                        }
                        .icon-jia{
                            // color: #e5e5e5;
                            color: #ac33c1;
                            font-size: 2.6vh;
                            cursor: pointer;
                        }
                        .inp{
                            width: 2.5vw;
                            background:none;  
                            outline:none;  
                            border:none;
                            text-align: center;
                            margin: 0 0.5vw;
                            margin-top: -0.3vh;
                            
                        }
                        .inp:focus{   
                            border:none;
                        }
                        .jinyong{
                            color: #e5e5e5;
                            cursor: no-drop;
                        }
                    }
                }
            }
        }
        .totalNum{
            font-size: 2.6vh;
            color: #d43030;
            padding: 1vh 1vw;
        }
        .jiezhangBtn{
            // position: absolute;
            // bottom: 2vh;
            // left: 5%;
            // width: 90%;
            margin: 1vh 1vw;
            background: #ac33c1;
            box-shadow: 0px 5px 5px 0px rgba(217, 94, 88, 0.16);
            height: 6.5vh;
            line-height: 6.5vh;
            text-align: center;
            color: #ffffff;
            font-size: 2.4vh;
            font-weight: 700;
            border-radius: 0.5vh;
            cursor: pointer;
        }
    }
    .center{
        width: 8.8vw;
        border-right: 1px solid #e5e5e5;
        padding: 3vh 0.6vw;
        // background: url(../../assets/tuihuo.png) no-repeat;
        // background-size: 100% 100%;
        // background: #3b3b45;
        >div{
            height: 5.7vh;
            line-height: 5.7vh;
            text-align: center;
            border-radius: 0.5vh;
            background: #ecedf3;
            margin-bottom: 1vh;
            cursor: pointer;
            &:nth-child(1){
                margin-bottom: 5vh;
            }
            &:nth-child(4){
                margin-bottom: 15vh;
            }
        }
    }
    .rightOrderReturn{
        // width: 59vw;
        flex: 1;
        background: #f5f5f5;
        color: #505050;
        padding: 1vh 1vw;
        overflow: auto;
        .title{
            font-size: 2.3vh;
            font-weight: 700;
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: 1vh;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span{
                font-size: 1.8vh;
                font-weight: normal;
                color: #b5b5b5;
                padding-left: 1vw;
            }
            .clearOrder{
                background: #ac33c1;
                color: #ffffff;
                box-shadow: 0px 5px 5px 0px rgba(217, 94, 88, 0.16);
                border-radius: 0.5vh;
                cursor: pointer;
                padding: 0.5vh 1vw;
                font-weight: normal;
            }
        }
        .info{
            display: flex;
            justify-content: space-between;
            padding: 1vh 0 2vh;
            font-size: 2vh;
        }
    }
    .right{
        // width: 59vw;
        flex: 1;
        background: #f5f5f5;
        .classify{
            display: flex;
            padding: 1.5vh 1vw;
            width: 59vw;
            position: relative;
            .all{
                width: 8.5vw;
                min-width: 8.5vw;
                height: 5vh;
                line-height: 5vh;
                background: #ffffff;
                margin-right: 1vw;
                text-align: center; 
                border-radius: 0.5vh;
                cursor: pointer;
            }
            .xiang{
                width: 0vw;
                height: 5vh;
                line-height: 5vh;
                background: #ffffff;
                margin-left: 1vw;
                text-align: center; 
                border-radius: 0.5vh;
                transition: all 1s;
                overflow: hidden;
                cursor: pointer;
            }
            .scroll_box{
                flex: 1;
                overflow: hidden;
                .scroll_box2{
                    display: flex;
                    transition: all 1s;
                }
            }
            .allBox{
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                width: 100%;
                padding: 1.5vh 1vw;
                flex-wrap: wrap;
                z-index: 99;
                margin-right: 1vw;
                background: #f5f5f5;
                >div{
                    margin-bottom: 1vh;
                }
            }
        }
        .goods_list{
            padding:0 0 1.5vh 1vw;
            width: 59vw;
            height: 85vh;
            overflow: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width : 0px;
                height: 3px;
            }
            .goods_item{
                >div:first-child{
                    width: 100%;
                    height: 100%;
                }
                float: left;
                // display: inline-block;
                background: #ffffff;
                // width: 10.6vw;
                // max-height: 15vw;
                margin-right: 1vw;
                margin-bottom: 1vh;
                padding: 0.6vh 0.5vw;
                border-radius: 0.5vh;
                text-align: center;
                cursor: pointer;
                // font-size: 2vh;
                position: relative;
                .bigFont{
                    // height: 13vh;
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .names{
                        // font-size: 2.5vh;
                        // font-weight: bold;
                        // width: 9.6vw;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        white-space: normal !important;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-family: 'PF' !important;
                        color:#777;
                    }
                    .price{
                        // font-size: 2.2vh;
                        font-weight: bold;
                        margin-top:1vh;
                    }
                }
                .weighing{
                    position: absolute;
                    top: 0.6vh;
                    right: 0.5vw;
                    background: #ffc300;
                    border-radius: 0.5vh;
                    font-size: 1.5vh;
                    padding: 0.1vh 0.3vw;
                }
                .img_box{
                    width: 100%;
                    height: 68%;
                    img{
                        // width: 9.6vw;
                        // height: 9.6vw;
                        width: 100%;
                        height: 100%;
                        border-radius: 0.5vh;
                    }
                }
                // &:nth-child(5n){
                //     margin-right: 0;
                // }
                .name{
                    // font-size: 2vh;
                    // width: 9.6vw;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .price{
                    color: #d95e58;
                    font-weight: 700;
                }
            }
        }
    }
    .searchGoods{
        position: fixed;
        background: rgba(0,0,0,0.5);
        top: 13vh;
        right: 2vw;
        padding: 1vh 0.6vw;
        width: 25vw;
        border-radius: 1vh;
        .searchInp{
            background: #ffffff;
            border-radius: 0.6vh;
            display: flex;
            align-items: center;
            height: 6vh;
            >span{
                width: 10vw;
                height: 2vh;
                line-height: 2vh;
                border-left: 1px solid #d95e58;
                text-align: center;
                color: #d95e58;
                font-weight: 700;
                cursor: pointer;
            }
            .inp , .inp:focus , .inp:active{
                height: 4vh;
                font-size: 2vh;
                color: #ff6565;
                border: none;
                outline:none;
                resize:none;
                margin-left: 1vw;
            }
        }
        .searchGoodsList{
            background: #ffffff;
            border-radius: 0.6vh;
            margin-top: 1vh;
            font-size: 2vh;
            padding: 1vh 0;
            max-height: 76vh;
            overflow-y: scroll;
            .item{
                height: 6vh;
                line-height: 6vh;
                padding: 0 1vw;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &::-webkit-scrollbar {
                width : 5px; 
                height: 5px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background   : #ff6565;
            }
            // &::-webkit-scrollbar-track {
            //     border-radius: 6px;
            //     background   : #ffebe8;
            // }
        }
        .noGoods{
            height: 6vh;
            line-height: 6vh;
            padding: 0 1vw;
            color: #a6a6a6;
        }
    }
}
</style>