<template>
    <div class="changePrice">
        <!-- 改价 -->
        <a-modal :footer="null" :title="'改价：' + changeGoods.goods_name" :visible="showModal" @cancel="handleCancel" width="50vw">
            <div class="changePrice_box">
                <div class="title">商品基本信息</div>
                <div class="row">
                    <div class="left">原价：</div>
                    <div class="right"><b>{{changeGoods.goods_original_price || goods_price}}</b></div>
                </div>
                <div class="row">
                    <div class="left">当前单价：</div>
                    <div class="right"><a-input class="inp" ref="input"  v-model="thisPrice" prefix="￥" @pressEnter="okHandle" 
                    @select="focus_select = true" @click="focus_select = false" @focus="focushandle"/></div>
                </div>
                <div :style="'height:' + height + 'vh;margin-top:5vh;'">
                    <numCode :height="height" v-model="thisPrice" @okHandle="okHandle"  :focus_select="focus_select"></numCode>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
export default {
    name:'changePrice',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        activeGoods:{
            default:0,
            type:Number
        }
    },
    components:{ numCode },
    data(){
        return{
            height:32,
            thisPrice:'',
            changeGoods:{},
            goods_price:'',
            focus_select:false
        }
    },
    watch:{
        showModal(val){
            if(val){
                this.changeGoods = this.returnGoods[this.activeGoods]
                this.thisPrice = this.changeGoods.goods_price
            }else{
                this.thisPrice = ''
                this.changeGoods = {}
            }
        },
        thisPrice(val){
            this.$set(this , 'thisPrice' , this.getTofixed(val))
            this.focus_select = false
        }
    },
    computed: {
        ...mapState({
            returnGoods:state=>state.fast_food.returnGoods,
            returnData:state=>state.fast_food.returnData,
        }),
    },
    methods:{
        ...mapMutations(['set_returnGoods']),
        handleCancel(){
            this.$emit('closeHandle')
        },
        okHandle(){
            console.log(this.thisPrice);
            if(this.thisPrice){
                if(this.thisPrice > this.changeGoods.total_pay_price * 1 / this.changeGoods.total_num){
                    this.$message.warning('商品退款单价不能大于支付单价')
                    return false
                }
                let list = JSON.parse(JSON.stringify(this.returnGoods))
                list[this.activeGoods].goods_price = this.thisPrice
                this.set_returnGoods(list)
                this.handleCancel()
            }else{
                this.$message.error('请输入金额')
            }
        },
        focushandle(){
            this.$refs.input.select();
        }
    }
}
</script>
<style lang="less" scoped>
.changePrice_box{
    .title{
        font-weight: 700;
        font-size: 2.5vh;
        margin-bottom: 2vh;
    }
    .row{
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        font-size: 2.2vh;
        .left{
            flex: 1;
        }
        .right{
            flex: 4;
        }
    }
    ::v-deep .ant-input-affix-wrapper{
        height: 4.2vh;
        .ant-input-prefix{
            font-size: 3vh;
            margin: 0.5vh 0;
        }
        .ant-input{
            height: 5.2vh;
            padding-left: 4vw;
            font-size: 2.5vh!important;
        }
    }
}
</style>