<template>
    <div class="confirmPrice">
        <!-- 退款确认 -->
        <a-modal :footer="null" title="确认退款" :visible="showModal" @cancel="handleCancel" width="50vw">
            <div class="confirmPrice_box">
                <div class="title">请确认退款信息</div>
                <div class="row">
                    <div class="left">可退金额：</div>
                    <div class="right"><b>{{returnData.payed_price}}</b></div>
                </div>
                <div class="row">
                    <div class="left">退款金额：</div>
                    <div class="right"><a-input class="inp" ref="input"  v-model="returnPrice" prefix="￥" @pressEnter="okHandle" 
                    @select="focus_select = true" @click="focus_select = false" @focus="focushandle"/></div>
                </div>
                <div :style="'height:' + height + 'vh;margin-top:5vh;'">
                    <numCode :height="height" v-model="returnPrice" @okHandle="okHandle"  :focus_select="focus_select"></numCode>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
export default {
    name:'confirmPrice',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        total_money:{
            default:null,
            type:[Number,String]
        }
    },
    components:{ numCode },
    data(){
        return{
            height:32,
            returnPrice:'',
            goods_price:'',
            focus_select:false
        }
    },
    watch:{
        showModal(val){
            if(val){
                this.returnPrice = this.total_money
            }else{
                this.returnPrice = ''
            }
        },
        returnPrice(val){
            this.$set(this , 'returnPrice' , this.getTofixed(val))
            this.focus_select = false
        }
    },
    computed: {
        ...mapState({
            returnData:state=>state.fast_food.returnData,
        }),
    },
    methods:{
        ...mapMutations(['set_returnGoods']),
        handleCancel(){
            this.$emit('closeHandle')
        },
        okHandle(){
            console.log(this.returnPrice);
            if(this.returnPrice){
                if(this.returnPrice > (this.returnData.payed_price * 1)){
                    this.$message.warning('退款金额不能大于可退金额')
                    return false
                }
                this.$emit('okHandle',this.returnPrice)
                this.$emit('closeHandle')
            }else{
                this.$message.error('请输入金额')
            }
        },
        focushandle(){
            this.$refs.input.select();
        }
    }
}
</script>
<style lang="less" scoped>
.confirmPrice_box{
    .title{
        font-weight: 700;
        font-size: 2.5vh;
        margin-bottom: 2vh;
    }
    .row{
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        font-size: 2.2vh;
        .left{
            flex: 1;
        }
        .right{
            flex: 4;
        }
    }
    ::v-deep .ant-input-affix-wrapper{
        height: 4.2vh;
        .ant-input-prefix{
            font-size: 3vh;
            margin: 0.5vh 0;
        }
        .ant-input{
            height: 5.2vh;
            padding-left: 4vw;
            font-size: 2.5vh!important;
        }
    }
}
</style>