<template>
    <div class="my-drawer">
        <a-drawer :width="width + 'vw'"
            :title="title"
            placement="right"
            :closable="false"
            :visible="visible"
            @close="onClose"
            :maskStyle="maskStyle"
            :bodyStyle="bodyStyle"
            >
            <slot></slot>
        </a-drawer>
        <div class="mask" :style="'width:' + maskWidth + 'vw'" v-if="visible">
            按ESC键 退出
        </div>
    </div>
</template>
<script>
export default {
    name:'my-drawer',
    props:{
        visible:{
            type:Boolean,
            default:false
        },
        title:{
            type:String,
            default:''
        },
        width:{
            type:Number,
            default:50
        },
        bodyStyle:{
            type:Object,
            default:null
        }
    },
    data(){
        return{
            maskWidth:50,
            maskStyle:{
                background:'rgba(0,0,0,0.1)'
            },
        }
    },
    mounted(){
        if(this.width){
            this.maskWidth = 100 - this.width
        }
    },
    methods:{
        onClose(){
            this.$emit('close')
        }
    }
}
</script>
<style lang="less" scoped>
.mask{
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    height: 100vh;
    line-height: 100vh;
    font-size: 3vh;
    color: #FFFDFD;
    background: rgba(0,0,0,0.3);
    z-index: 999;
}
</style>