<template>
    <div class="practice">
        <!-- 规格 -->
        <a-modal :title="weightGoods.goods_name" :visible="showModal" @cancel="handleCancel" width="50vw" :maskClosable="false">
            <div class="practice_box">
                <div v-if="weightGoods.spec_type == 20">
                    <span class="title">零售价：</span>
                    <span class="options">
                        ￥{{goods_price}}
                    </span>
                </div>
                <div v-if="guigeArr && guigeArr.length">
                    <div class="title">规格：</div>
                    <div class="options">
                        <a-button :class="guige == item.spec_name ? 'smallOkBtn' : 'smallClearBtn'" v-for="(item,i) in guigeArr" :key="i" @click="guige = item.spec_name;goods_price=item.spec_price.toFixed(2)">{{item.spec_name}}</a-button>
                    </div>
                </div>
            </div>
            <template slot="footer">
                <div class="bottomBtn">
                    <a-button class="clearBtn" @click="handleCancel">关闭</a-button>
                    <a-button class="OkBtn" @click="handleOk">确定</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
export default {
    name:'practice',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
    },
    components:{ numCode },
    data(){
        return{
            guigeArr:[],
            guige:'',
            goods_price:'',
            height:22,
        }
    },
    watch:{
        showModal(val){
            if(val){
                console.log(this.weightGoods);
                this.guigeArr = this.weightGoods.spec_content ? JSON.parse(this.weightGoods.spec_content) : []
                this.goods_price = this.weightGoods.goods_price
            }else{
                this.guige = ''
            }
        },
    },
    computed: {
        ...mapState({
            returnGoods:state=>state.fast_food.returnGoods,
            weightGoods:state=>state.fast_food.weightGoods,
        }),
    },
    methods:{
        ...mapMutations(['set_weightGoods','set_returnGoods']),
        handleCancel(){
            this.$emit('closeHandle')
        },
        handleOk(){
            if(this.guigeArr.length && !this.guige){
                this.$message.error('请选择规格')
            }else{
                let weightGoods = JSON.parse(JSON.stringify(this.weightGoods))
                weightGoods.goods_price = this.goods_price
                weightGoods.goods_original_price = this.goods_price
                console.log(this.guige)
                weightGoods.spec_content = this.guige
                let list = JSON.parse(JSON.stringify(this.returnGoods))
                list.unshift(weightGoods)
                this.set_returnGoods(list)
                this.set_weightGoods({})
                this.handleCancel()
            }
        },
    }
}
</script>
<style lang="less" scoped>
.practice_box{
    >div{
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        .title{
            font-weight: 700;
            font-size: 2vh;
            width: 7vw;
            text-align: right;
            margin-right: 1vw;
        }
        .options{
            flex: 1;
            .smallOkBtn,.smallClearBtn{
                margin: 0.5vh 1vw 0.5vh 0;
                width: 8.2vw;
                height: 4.6vh;
                text-align: center;
                padding: 0;
            }
        }
        .xiaoji{
            display: flex;
            justify-content: space-between;
        }
    }
    
}
.bottomBtn{
    padding: 1vh 8vw;
    display: flex;
    justify-content: space-around;
}
</style>