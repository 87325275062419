//快餐结算
import request from "@/request/request";


//开台
export function Founding(data) {
    return request({
        url: "/clerk/table/founding",
        method: "post",
        data,
    });
}

//下单
export function orderCreate(data) {
    return request({
        url: "/clerk/order/create",
        method: "post",
        data
    });
}

//获取订单详细
export function OrderDetail(params) {
    return request({
        url: "/clerk/order/detail",
        method: "get",
        params
    });
}

//设置 订单 折扣
export function orderDiscount(data) {
    return request({
        url: "/clerk/order/discount",
        method: "post",
        data
    });
}
//设置 订单 优免
export function orderFree(data) {
    return request({
        url: "/clerk/order/free",
        method: "post",
        data
    });
}
//发起订单支付
export function orderpay(data) {
    return request({
        url: "/clerk/order/orderpay",
        method: "post",
        data
    });
}

//完成结算
export function finish(data) {
    return request({
        url: "/clerk/order/finish",
        method: "post",
        data
    });
}

//获取支付状态
export function orderpaystatus(data) {
    return request({
        url: "/clerk/order/orderpaystatus",
        method: "post",
        data
    });
}

// 选中/取消  会员
export function orderUser(data) {
    return request({
        url: "/clerk/order/user",
        method: "post",
        data
    });
}

// 整单备注
export function orderRemark(data) {
    return request({
        url: "/clerk/order/orderRemark",
        method: "post",
        data
    });
}

// 商品退货
export function goodsRefund(data) {
    return request({
        url: "/clerk/goods/goodsRefund",
        method: "post",
        data
    });
}

//搜索订单
export function billList(data) {
    return request({
        url: "/clerk/order/billList",
        method: "post",
        data: data,
    });
}

//整单作废
export function orderInvalid(data) {
    return request({
        url: "/clerk/order/orderInvalid",
        method: "post",
        data: data,
    });
}

//获取上一单信息
export function getPreOrder(data) {
    return request({
        url: "/clerk/order/getPreOrder",
        method: "post",
        data: data,
    });
}
// 扫码自动识别商品
export function getGoodsByBarcode(data) {
    return request({
        url: "/clerk/goods/getGoodsByBarcode",
        method: "post",
        data: data,
    });
}
// 新增商品
export function addGoods(data) {
    return request({
        url: "/clerk/goods/addGoods",
        method: "post",
        data: data,
    });
}
// 获取商品单位
export function units(data) {
    return request({
        url: "/clerk/goods/units",
        method: "post",
        data: data,
    });
}