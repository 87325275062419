<template>
    <div class="searchInput">
        <a-input class="inputbox" v-model="value" :placeholder="placeholder" ref="inputbox" @blur="inputBlur" @pressEnter="searchEvent">
            <template slot="suffix">
                <a-icon type="close" style="cursor: pointer;margin-right:1vw;" v-show="showClose" @click="deleteHandle"/>
                <span @click="searchEvent" style="border-left:1px solid #999999;padding-left:0.8vw;">查询</span>
            </template>
        </a-input>
        <div class="list">
            <div class="search_item" v-for="item in searchData" :key="item.goods_id" @click="change(item)">{{orderList.length ? '商品：' :''}}{{ item.goods_name }}</div>
            <div class="search_item" v-for="item in orderList" :key="item.order_no" @click="change(item)">订单：{{ item.order_no }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        searchData:{
            type:Array,
            default:[]
        },
        orderList:{
            type:Array,
            default:[]
        },
        placeholder:{
            type:String,
            default:''
        },
        clearVal:{
            type:Number,
            default:0
        },
        haveBlur:{
            type:Boolean,
            default:true
        },
    },
    watch:{
        clearVal(val,oldVal){
            if(val == oldVal + 1){
                setTimeout(() => {
                    this.value = ''
                },100)
            }
        },
        haveBlur(val){
            if(val){
                this.inputBlur()
            }
        },
        value(val){
            if(!!val){
                this.showClose = true
                // this.searchHandle()
            }else{
                this.showClose = false
                this.$emit('clearData')
            }
        }
    },
    data(){
        return{
            value:'',
            oldValue:'',
            canSearch:true,
            is_bulr:true,
            showClose:false
        }
    },
    mounted(){
        this.inputBlur()
    },
    methods:{
        searchEvent(){
            this.$emit('search' , this.value)
        },
        searchHandle(){
            if(this.canSearch){
                if(this.oldValue == this.value){
                    this.canSearch = false
                    this.$emit('search' , this.value)
                    setTimeout(() => {
                        this.canSearch = true
                    },200)
                }else{
                    setTimeout(() => {
                        this.oldValue = this.value
                        this.searchHandle()
                    },100)
                }
            }
        },
        change(item){
            this.$emit('change' , item)
        },
        inputBlur(){
            setTimeout(() => {
                if(this.haveBlur && this.is_bulr && !this.value){
                    this.$refs.inputbox.focus();
                }
            },100)
        },
        deleteHandle(){
            this.value = ''
        }
    },
    beforeDestroy(){
        this.is_bulr = false
    }
}
</script>
<style lang="less" scoped>
.searchInput{
    position: relative;
    height: 5vh;
    width: 100%;
    
    .inputbox{
        height: 5vh;
        width: 100%;
        font-size: 2vh!important;
        ::v-deep .ant-input{
            height: 5vh;
        }
    }
    .list{
        background: #ffffff;
        border-radius: 0.5vh;
        margin-top: 0.5vh;
        box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.2);
        max-height: 50vh;
        overflow:auto;
        .search_item{
            height: 5vh;
            line-height: 5vh;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 1vh;
            color: #a6a6a6;
            &:hover{
                background: #fff4f2;
                color: #d95e58;
            }
        } 
    }
}
</style>    