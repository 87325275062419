<template>
    <div class="weighing">
        <!-- 称重 -->
        <a-modal :footer="null" :title="weightGoods.goods_name" :visible="showModal" @cancel="handleCancel" width="50vw" :maskClosable="false">
            <div class="changePrice_box">
                <div class="title">商品基本信息</div>
                <div class="row">
                    <div class="left">零售价：</div>
                    <div class="right"><b>￥{{weightGoods.goods_price}}</b></div>
                </div>
                <div class="row">
                    <div class="left">小计：</div>
                    <div class="right">{{(weightGoods.goods_price * (Number(weightNum) || 0)).toFixed(2)}}</div>
                </div>
                <div class="row">
                    <div class="left">重量：</div>
                    <div class="right"><a-input class="inp" v-model="weightNum" suffix="| KG" @pressEnter="okHandle" :disabled="disabled"/></div>
                </div>
                <div :style="'height:' + height + 'vh;margin-top:5vh;'">
                    <numCode :height="height" v-model="weightNum" @okHandle="okHandle"></numCode>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
export default {
    name:'weighing',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        activeGoods:{
            default:0,
            type:Number
        }
    },
    components:{ numCode },
    data(){
        return{
            height:32,
            weightNum:"",
            times:"",
            // fina:"",
            disabled:false

        }
    },
    watch:{
        showModal(val){
            if(val){
                const instllObject= JSON.parse(localStorage.getItem('install'))?JSON.parse(localStorage.getItem('install')):{use:2,factory:undefined,type:undefined,com:undefined,potter:undefined,}
                if(instllObject.use==1 && this.weightGoods.is_weighing == 2){
                    const com=instllObject.com;
                    const potter=parseFloat(instllObject.potter);
                    const a=bound.getWeight(com, potter).replace(/[\x00-\x1f]+/g, '');
                    const b=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))).toFixed(2);
                    if(b && b !=='NaN' && b !== 'undefined' && b !== 'null'   ){
                        if(a.indexOf("-")!=-1){
                            this.weightNum=-Number(b)
                        }else{
                            this.weightNum=b;
                        }
                    }else{
                        this.weightNum=0.00
                    }
                    this.times=setInterval(() => {
                            setTimeout( 
                            this.weight(com,potter)
                            , 0)           
                    },200);
                }
            }
        },
        weightNum(val){
            const instllObject= JSON.parse(localStorage.getItem('install'))?JSON.parse(localStorage.getItem('install')):{use:2,factory:undefined,type:undefined,com:undefined,potter:undefined,}
            if(instllObject.use==2){
                this.$set(this , 'weightNum' , this.getTofixed(val))
            }
        }
    },
    computed: {
        ...mapState({
            returnGoods:state=>state.fast_food.returnGoods,
            weightGoods:state=>state.fast_food.weightGoods,
        }),
    },
    methods:{
        ...mapMutations(['set_weightGoods','set_returnGoods']),
        handleCancel(){
            clearInterval(this.times);
            this.$emit('closeHandle')
           
        },
        weight(com,potter){
            const a=bound.getWeight(com, potter).replace(/[\x00-\x1f]+/g, '');
            const b=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))).toFixed(2);
            if(b && b !=='NaN' && b !== 'undefined' && b !== 'null'   ){
                if(a.indexOf("-")!=-1){
                    this.weightNum=-Number(b);
                    this.disabled=true;
                }else{
                    this.weightNum=b;
                    this.disabled=true;
                }
            }else{
                this.weightNum=0.00;
                this.disabled=true;
            }
        },
        okHandle(){
            console.log(this.weightNum)
            if(this.weightNum){
                let weightGoods = JSON.parse(JSON.stringify(this.weightGoods))
                if(this.weightGoods.is_weighing == 2 && this.weightNum){
                    weightGoods.refund_num = this.weightNum
                }
                // if(weightGoods.avoid || weightGoods.practice || weightGoods.taste){
                //     this.set_weightGoods(weightGoods)
                //     this.$emit('openGuige')
                // }else{
                    let list = JSON.parse(JSON.stringify(this.returnGoods))
                    list.unshift(weightGoods)
                    this.set_returnGoods(list)
                    this.set_weightGoods({})
                    this.handleCancel()
                // }
            }else{
                this.$message.error('请进行商品称重')
            }
        },
    },
}
</script>
<style lang="less" scoped>
.changePrice_box{
    .title{
        font-weight: 700;
        font-size: 2.5vh;
        margin-bottom: 2vh;
    }
    .row{
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        font-size: 2.2vh;
        .left{
            flex: 1;
        }
        .right{
            flex: 4;
        }
    }
    ::v-deep .ant-input-affix-wrapper{
        height: 4.2vh;
        .ant-input-suffix{
            font-size: 2.5vh;
            margin: 0.5vh 0;
        }
        .ant-input{
            height: 5.2vh;
            font-size: 2.5vh!important;
        }
    }
}
</style>